<template>
  <div id="vital1" ref="vital1" class="news_content">
    <div class="index1"></div>
    <div class="index3">
<!--      <div v-show="total > 0">-->
        <div v-for="item in newsList" :key="item.id" class="news_row">
          <img :src="item.mainImage" alt="" />
          <div class="content_box">
            <div class="title">{{ item.title }}</div>
            <div class="summary">
              {{ item.summary ? item.summary : item.title }}
            </div>
            <div class="time">
              <div>{{ item.createDate }}</div>
              <el-button type="text" @click="goDetail(item)"
                >查看详情</el-button
              >
            </div>
          </div>
        </div>
        <div class="page">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentNum"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { getNews } from "@/api/home";
export default {
  name: "news",
  components: {},
  data() {
    return {
      currentNum: 1,
      total: 0,
      newsList: [],
      pageSize: 10,
      showDetailId: "",
      newsDetail: {},
      currentPage: 1,
    };
  },
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let params = {
        pageNum: this.currentNum,
        pageSize: this.pageSize,
        // deleteFlag: 0,
        newsType: 1,
      };
      getNews(params).then((res) => {
        this.total = res.result.total;
        this.newsList = res.result.list;
        console.log(res.result)
      });
    },
    goDetail(item) {
      this.$router.push({
        path: "/news/detail/" + item.id,
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentNum = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.news_content {
  background-color: rgba(248, 248, 248, 100);
  padding-bottom: 50px;
}
.index1 {
  height: 410px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/beishankou/news_bg.png")
    no-repeat center center;
  background-size: 100% 100%;
  text-align: left;
  background-color: white;
  // padding-left: 340px;
  // padding-right: 660px;
}
.index3 {
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 70px;
  width: 1500px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  padding: 30px 70px 60px 30px;

  // border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 100);
  .news_row {
    width: 100%;
    height: 200px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    margin-top: 10px;

    img {
      width: 225px;
      height: 183px;
      margin-right: 75px;
    }
    .content_box {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-bottom: 1px solid #c1c1c1;
      .title {
        height: 33px;
        font-size: 22px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 26px;
        text-align: left;
      }
      .summary {
        height: 31px;
        font-size: 18px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        flex: 1;
        text-align: left;
        margin-top: 10px;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
      }
      .time {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
.page {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
